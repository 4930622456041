import { Popover, Tag } from 'antd';
import { FC } from 'react';
import { TranslatedTagProps } from './Product.types';

interface ShopLanguage {
  id: string;
  name: string;
  default?: boolean;
  position: number;
}

type ShopLanguages = Record<string, ShopLanguage>;

export const TranslatedTag: FC<TranslatedTagProps> = ({ shop, translates }) => {
  const shopLanguages = Object.entries(JSON.parse(String(shop?.languages || '{}')) as ShopLanguages)
    .map(([key, value]) => ({
      key, // Use the key (e.g., "AL", "EN")
      ...value,
    }))
    .sort((a, b) => a.position - b.position);

  const statusTag = (lang: any) =>
    translates?.some(i => i.language_id === lang.id) ? 'success' : 'default';

  return (
    <div className="langauge_container">
      {shopLanguages.slice(0, 3).map(item => (
        <Tag color={statusTag(item)} key={item.key}>
          {item.key.toUpperCase()}
        </Tag>
      ))}

      {shopLanguages.length >= 4 && (
        <Popover
          overlayClassName="translated_languages_popover"
          placement="bottom"
          content={shopLanguages.slice(3).map(item => (
            <Tag color={statusTag(item)} key={item.key}>
              {item.key.toUpperCase()}
            </Tag>
          ))}
          trigger="click"
        >
          <div className="count_language">+{shopLanguages.length - 3}</div>
        </Popover>
      )}
    </div>
  );
};
