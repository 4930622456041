import {
  Avatar,
  Breadcrumb,
  Checkbox,
  Image,
  Popconfirm,
  Space,
  Table,
  TableProps,
  Tooltip,
  TreeSelect,
} from 'antd';
import queryString from 'query-string';
import { useEffect, useMemo, useState } from 'react';
import '../../../sass/pages/_dashboard.scss';
import * as Icon from 'react-feather';

import {
  AppstoreAddOutlined,
  CheckSquareOutlined,
  EyeOutlined,
  PictureOutlined,
  ShoppingOutlined,
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { PermissionEnum } from '../../../enums/Permission.enum';
import { BrandModel, ProductModel, SellerModel, UserProductModel } from '../../../interfaces';
import { SortOrder } from 'antd/lib/table/interface';
import { Check, RefreshCw, Star } from 'react-feather';
import { ProductShopStatusEnum, ProductStatusEnum } from '../../../enums/ProductStatus.enum';
import { useAuthStore } from '../../../store';
import { checkStatusAllProduct } from '../../../utils/checkProductStatus';
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
import { SellerIDsEnum } from '../../../enums/SellerIDs.enum';
import { ListingProductsProps } from '../Products/Product.types';
import { TranslatedTag } from '../Products/TranslatedTag';

export const ListingProducts = ({
  loading,
  languages,
  shop,
  products,
  searchValue,
  selectedRows,
  productCount = 0,
  handleSelectAllProducts,
  setSelectedRows,
  deleteProducts,
  archiveProducts,
  sendProductsToShop,
  changeProductStatus,
}: ListingProductsProps) => {
  // hooks
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { user } = useAuthStore();
  const { search } = useLocation();
  const location = useLocation();
  const [selectedValues, setSelectedValues] = useState<string[]>([]);
  const getParams = new URLSearchParams(location.search);
  const showTranslatedParam = getParams.get('language');

  const [exportType, setExportType] = useState<Record<'main' | 'configurator' | 'image', boolean>>({
    main: true,
    image: false,
    configurator: false,
  });

  // local state
  const searchParsed = useMemo(() => queryString.parse(search), [search]);

  const handleCopy = (event: any) => {
    const clipboardData = event.clipboardData || window.Clipboard;
    const selectedText = window?.getSelection()?.toString().trim();

    clipboardData.setData('text/plain', selectedText);
    event.preventDefault();
  };

  const handleDeleteById = (id: string) => () => deleteProducts([id]);
  const handleArchiveById = (id: string) => () => archiveProducts([id]);

  const handleSendInShop = (id: string, type?: { main: boolean; image: boolean }) => () => {
    if (sendProductsToShop && id) sendProductsToShop([id], type || exportType);
  };

  const checkAccess = (permission: string) => user?.permissions?.includes(permission);

  const language = languages.find(i => i.iso_code === searchValue?.language);

  const orderParams = useMemo(
    () =>
      searchParsed?.orderBy && searchParsed?.orderKey
        ? {
            orderKey: searchParsed.orderKey.toString(),
            orderBy: (searchParsed?.orderBy == 'asc'
              ? 'ascend'
              : 'descend') as unknown as SortOrder,
          }
        : null,
    [searchParsed],
  );

  const handleViewProduct = (id: string) => () => {
    navigate({ search: queryString.stringify({ ...searchParsed, id }) }, { replace: true });
  };

  const handleChange: TableProps<ProductModel>['onChange'] = (
    _pagination,
    _filters,
    sorter: any,
  ) => {
    navigate(
      {
        search: queryString.stringify(
          {
            ...searchParsed,
            ...(sorter?.order
              ? {
                  orderKey: sorter?.columnKey,
                  orderBy: sorter?.order == 'ascend' ? 'asc' : 'desc',
                }
              : { orderKey: null, orderBy: null }),
          },
          { skipNull: true },
        ),
      },
      { replace: true },
    );
  };

  const onChange = (value: string[]) => {
    const filteredParsed = Object.entries(searchParsed).filter(
      ([key, _value]) => !key.includes('show'),
    );
    const parsed = Object.fromEntries([...value.map(i => i.split('-')), ...filteredParsed]);

    navigate({ search: queryString.stringify({ ...parsed }) }, { replace: true });
  };

  useEffect(() => {
    const { search } = location;
    const searchParsed = queryString.parse(search);

    const selectedValues = Object.entries(searchParsed)
      .filter(([key, _value]) => key.includes('show'))
      .map(([key, _value]) => key + '-' + _value);

    setSelectedValues(selectedValues);
  }, [location.search]);

  const treeData = [
    {
      value: 'show_translated',
      title: 'Translated',
      disabled: true,
      children: [
        {
          value: 'show_translated-1',
          title: 'Yes',
        },
        {
          value: 'show_translated-0',
          title: 'No',
        },
      ],
    },
    {
      value: 'show_categories',
      title: 'Categories',
      disabled: true,
      children: [
        {
          value: 'show_categories-1',
          title: 'Yes',
        },
        {
          value: 'show_categories-0',
          title: 'No',
        },
      ],
    },
    {
      value: 'brands',
      title: 'Brands',
      disabled: true,
      children: [
        {
          value: 'show_brands-1',
          title: 'Yes',
        },
        {
          value: 'show_brands-0',
          title: 'No',
        },
      ],
    },
    {
      value: 'updated',
      title: 'Has changed',
      disabled: true,
      children: [
        {
          value: 'show_updated-2',
          title: 'Yes',
        },
        {
          value: 'show_updated-0',
          title: 'No',
        },
      ],
    },
    {
      value: 'show_images',
      title: 'Has images',
      disabled: true,
      children: [
        {
          value: 'show_images-1',
          title: 'Yes',
        },
        {
          value: 'show_images-0',
          title: 'No',
        },
      ],
    },
    {
      value: 'show_price',
      title: 'Has Price',
      disabled: true,
      children: [
        {
          value: 'show_price-1',
          title: 'Yes',
        },
        {
          value: 'show_price-0',
          title: 'No',
        },
      ],
    },
    {
      value: 'show_stock',
      title: 'Has Stock',
      disabled: true,
      children: [
        {
          value: 'show_stock-1',
          title: 'Yes',
        },
        {
          value: 'show_stock-0',
          title: 'No',
        },
      ],
    },
    {
      value: 'show_is_in_vendor_list',
      title: 'In vendor list',
      disabled: true,
      children: [
        {
          value: 'show_is_in_vendor_list-1',
          title: 'Yes',
        },
        {
          value: 'show_is_in_vendor_list-0',
          title: 'No',
        },
      ],
    },
  ];

  const onClear = () => {
    navigate(
      {
        search: queryString.stringify({
          status: searchParsed?.status,
          seller: searchParsed?.seller,
          category: searchParsed?.category,
          user: searchParsed?.user,
        }),
      },
      { replace: true },
    );
  };

  const columns = [
    {
      title: 'Thumbnail',
      render: (e: any) => {
        if (e?.product_images?.length == 0) {
          return (
            <div className="images-wrapper">
              <div className="extra-images full-height">
                <Avatar
                  shape="square"
                  size={{ sm: 50, xxl: 50 }}
                  style={{
                    width: '50px',
                    height: '50px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  alt="Default product image"
                  icon={<PictureOutlined />}
                />
              </div>
            </div>
          );
        } else {
          const findImage = e?.product_images?.find(
            (x: any) => x.is_cover === 1 && x.is_deleted === 0,
          );

          return (
            <Image
              width={50}
              height={50}
              src={findImage?.url == undefined ? e?.product_images?.[0]?.url : findImage?.url}
              onCopy={handleCopy}
              alt=""
              style={{ objectFit: 'contain' }}
            />
          );
        }
      },
    },
    {
      title: 'SKU Intern',
      dataIndex: 'sku_intern',
      key: 'sku_intern',
      sorter: true,
      minWidth: '120px',
      sortOrder: orderParams?.orderKey === 'sku_intern' ? orderParams.orderBy : null,
      ...(orderParams?.orderKey === 'sku_intern' && { defaultSortOrder: orderParams.orderBy }),
      render: (value: string, data: any) => (
        <span
          className="table__cursor"
          onClick={handleViewProduct(data.id)}
          onCopy={handleCopy}
          style={{ textDecoration: data.is_in_vendor_list ? 'none' : 'line-through' }}
        >
          {value}
          {!data.is_simple && (
            <img
              src="https://static-00.iconduck.com/assets.00/variants-icon-512x512-hdqssuzp.png"
              alt="Variants"
              style={{ marginLeft: '5px', width: '16px', height: '16px' }}
            />
          )}
        </span>
      ),
    },
    {
      title: 'SKU Ext.',
      dataIndex: 'sku',
      minWidth: '120px',
      key: 'sku',
      sorter: true,
      sortOrder: orderParams?.orderKey === 'sku' ? orderParams.orderBy : null,
      ...(orderParams?.orderKey === 'sku' && { defaultSortOrder: orderParams.orderBy }),
      render: (value: string, data: any) => (
        <span
          className="table__cursor"
          onClick={handleViewProduct(data.id)}
          onCopy={handleCopy}
          style={{ textDecoration: data.is_in_vendor_list ? 'none' : 'line-through' }}
        >
          {value}
        </span>
      ),
    },
    {
      title: `Reseller`,
      dataIndex: 'seller',
      key: 'seller',
      render: (value: SellerModel, data: any) => (
        <span style={{ textDecoration: data.is_in_vendor_list ? 'none' : 'line-through' }}>
          {value?.name}
        </span>
      ),
    },
    {
      title: `Brand`,
      dataIndex: 'brand',
      key: 'brand',
      render: (value: BrandModel, data: any) => (
        <span style={{ textDecoration: data.is_in_vendor_list ? 'none' : 'line-through' }}>
          {value?.name}
        </span>
      ),
    },
    {
      title: 'Product name',
      dataIndex: 'product_translates',
      key: 'name',
      sorter: true,
      width: '260px',
      sortOrder: orderParams?.orderKey === 'name' ? orderParams.orderBy : null,
      ...(orderParams?.orderKey === 'name' && { defaultSortOrder: orderParams.orderBy }),
      render: (values: any[], data: any) => (
        <p
          className="text__dots table__cursor"
          onClick={handleViewProduct(data.id)}
          onCopy={handleCopy}
          style={{ textDecoration: data.is_in_vendor_list ? 'none' : 'line-through' }}
        >
          {showTranslatedParam == null
            ? values.find(i => i.language_id === language?.id)?.name
            : values.find(i => i.language_id === showTranslatedParam)?.name}
        </p>
      ),
    },
    {
      ...(!searchParsed?.status && {
        title: 'Status',
        key: 'status',
        dataIndex: 'status',
        width: '110px',
        render: (value: any, data: any) => {
          const statusText = checkStatusAllProduct(value, data?.productShop?.status);
          return (
            <div style={{ textAlign: 'center' }}>
              <div className={statusText}>{statusText?.replace('-', ' ')}</div>
            </div>
          );
        },
      }),
    },
    {
      title: 'Mapped Categories',
      dataIndex: 'product_categories',
      key: 'name',
      width: '200px',
      render: (values: any[], data: any) => {
        const categoriesForProduct = values.filter(category =>
          data.categories.some(
            (productCategory: any) =>
              productCategory.category_id === category.id &&
              !productCategory.is_deleted &&
              category.seller_id === SellerIDsEnum.SHOPWARE,
          ),
        );
        return (
          <>
            {categoriesForProduct.map((category: any, index: any) => (
              <Breadcrumb key={index} style={{ fontSize: '11px' }}>
                <Breadcrumb.Item className="text__dots table__cursor">
                  {category.name}
                </Breadcrumb.Item>
              </Breadcrumb>
            ))}
          </>
        );
      },
    },
    {
      title: (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TreeSelect
            multiple
            className="tree__select"
            style={{ width: '100%' }}
            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
            placeholder="Filter"
            allowClear
            treeDefaultExpandAll
            onChange={onChange}
            treeData={treeData}
            onClear={onClear}
            treeCheckable={false}
            treeCheckStrictly={false}
            popupClassName="tree__select-wrapper"
            value={selectedValues}
          />
        </div>
      ),
      key: 'action',
      width: 360,
      render: (e: any) => (
        <Space>
          <Tooltip title="Is product translated" placement="top">
            <p>
              <TranslatedTag languages={languages} shop={shop} translates={e.product_translates} />
            </p>
          </Tooltip>
          <Tooltip title="Product categories" placement="top">
            <span>
              <AppstoreAddOutlined
                className={
                  e?.product_categories
                    ?.filter(
                      (productCategory: any) =>
                        productCategory.seller_id === SellerIDsEnum.SHOPWARE,
                    )
                    .some((productCategory: any) =>
                      e?.categories?.some(
                        (category: any) =>
                          category.category_id === productCategory.id && !category.is_deleted,
                      ),
                    )
                    ? 'success-category'
                    : 'not-success'
                }
                size={17}
              />
            </span>
          </Tooltip>
          <Tooltip title="Product Brands" placement="top">
            <a className={e?.brand?.mapped_brand ? 'success-category' : 'not-success-approve'}>
              <Star size={15} />
            </a>
          </Tooltip>
          <Tooltip title="Variant Price" placement="top">
            <p className={e.price == 0 ? 'not-success-approve' : 'success-category'}>€</p>
          </Tooltip>
          {e?.status != '4' && (
            <Tooltip title="Is product approved" placement="top">
              <a
                className={
                  e.status == '4' || e?.status == '2' || e?.status === ProductShopStatusEnum.IN_SHOP
                    ? 'success-category'
                    : 'not-success-approve'
                }
              >
                <Check size={16} />
              </a>
            </Tooltip>
          )}

          <Tooltip
            title={
              e.status == ProductShopStatusEnum.IN_SHOP && e.is_updated == true
                ? 'Product in shop is updated'
                : e.status != ProductShopStatusEnum.IN_SHOP
                ? 'Product not in shop'
                : 'Product in shop'
            }
            placement="top"
          >
            <a
              className={
                e.status === ProductShopStatusEnum.IN_SHOP && e.is_updated == true
                  ? 'success-category-red'
                  : e.status != ProductShopStatusEnum.IN_SHOP
                  ? 'not-success-approve'
                  : 'success-category'
              }
            >
              <RefreshCw size={16} />
            </a>
          </Tooltip>

          {Number(searchParsed?.status) === ProductStatusEnum.PENDING && (
            <Popconfirm
              title={
                <div>
                  <p className="title">{t('send_product_in_approve')}</p>
                  <p className="text">{t('send_product_in_shop_subtitle')}</p>
                </div>
              }
              okText="Yes"
              cancelText="No"
              onConfirm={() => changeProductStatus(e.id)}
            >
              <Tooltip title="Approve this product" placement="top">
                <a className="action-item">
                  <CheckSquareOutlined size={20} />
                </a>
              </Tooltip>
            </Popconfirm>
          )}

          {[ProductStatusEnum.APPROVED].includes(Number(searchParsed?.status)) && (
            <Popconfirm
              title={
                <div>
                  <p className="title">{t('send_product_in_shop')}</p>
                </div>
              }
              okText="Yes"
              cancelText="No"
              onConfirm={handleSendInShop(e.id || '', { main: true, image: true })}
            >
              <Tooltip title="Send product in shop" placement="top">
                <a className="action-item">
                  <ShoppingOutlined size={20} alt="Send to shop" />
                </a>
              </Tooltip>
            </Popconfirm>
          )}

          {(searchParsed?.status === ProductShopStatusEnum.REMOVED ||
            searchParsed?.status === ProductShopStatusEnum.IN_SHOP) && (
            <Popconfirm
              title={
                <div>
                  <p className="title">{t('send_product_in_shop')}</p>
                  <div
                    style={{
                      display: 'flex',
                      marginBottom: '40px',
                      alignItems: 'start',
                      flexFlow: 'column',
                    }}
                  >
                    <div>
                      <Checkbox
                        defaultChecked
                        disabled
                        onChange={(e: CheckboxChangeEvent) => {
                          setExportType({ ...exportType, main: e?.target?.checked });
                        }}
                      >
                        Main data
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        onChange={(e: CheckboxChangeEvent) => {
                          setExportType({ ...exportType, image: e?.target?.checked });
                        }}
                      >
                        Repair Images
                      </Checkbox>
                    </div>
                    <div>
                      <Checkbox
                        onChange={(e: CheckboxChangeEvent) => {
                          setExportType({ ...exportType, configurator: e?.target?.checked });
                        }}
                      >
                        Repair Product configurators
                      </Checkbox>
                    </div>
                  </div>
                </div>
              }
              okText="Yes"
              cancelText="No"
              onConfirm={handleSendInShop(e.id || '')}
            >
              <Tooltip title="Send product in shop" placement="top">
                <a className="action-item">
                  <ShoppingOutlined size={20} alt="Send to shop" />
                </a>
              </Tooltip>
            </Popconfirm>
          )}

          {(Number(searchParsed?.status) === ProductStatusEnum.IGNORED ||
            Number(searchParsed?.status) === ProductStatusEnum.REMOVED ||
            Number(searchParsed?.status) === ProductStatusEnum.IGNORED ||
            searchParsed?.status === ProductShopStatusEnum.REMOVED) &&
            checkAccess(PermissionEnum.DELETE_PRODUCT) && (
              <Popconfirm
                title={
                  <div>
                    <p className="title">{t('archive_product')}</p>
                    <p className="text">{t('archive_subtitle_product')}</p>
                  </div>
                }
                okText="Yes"
                cancelText="No"
                onConfirm={handleArchiveById(e.id)}
              >
                <Tooltip title="Archive" placement="top">
                  <a className="action-item">
                    <Icon.Archive size={13} />
                  </a>
                </Tooltip>
              </Popconfirm>
            )}
          <Tooltip title="View product" placement="top">
            <a className="action-item" onClick={handleViewProduct(e.id)}>
              <EyeOutlined size={17} alt="View" />
            </a>
          </Tooltip>
          {searchParsed?.status === ProductShopStatusEnum.IN_SHOP &&
            checkAccess(PermissionEnum.DELETE_PRODUCT) && (
              <Popconfirm
                title={
                  <div>
                    <p className="title">{t('delete_remove_from_shop')}</p>
                    <p className="text">{t('delete_subtitle_remove_from_shop')}</p>
                  </div>
                }
                okText="Yes"
                cancelText="No"
                onConfirm={handleDeleteById(e.id)}
              >
                <Tooltip
                  title={
                    searchParsed?.status === ProductShopStatusEnum.IN_SHOP &&
                    'Remove (hide from shop)'
                  }
                  placement="right"
                >
                  <a className="action-item">
                    <Icon.XOctagon size={16} />
                  </a>
                </Tooltip>
              </Popconfirm>
            )}
        </Space>
      ),
    },
  ];

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexFlow: 'row',
          marginBottom: '10px',
        }}
      >
        <span className="select-btn">
          {selectedRows.length > 0 ? `Selected ${selectedRows.length} items` : ''}
        </span>
        {productCount <= 10000 && selectedRows.length > 0 && (
          <>
            {'  '}
            <span className="select-btn-green" onClick={handleSelectAllProducts}>
              Select all {productCount} products
            </span>
          </>
        )}
      </div>
      <Table
        loading={loading}
        className="table"
        columns={columns}
        pagination={false}
        dataSource={products}
        onChange={handleChange}
        rowKey={({ id }) => id}
        rowSelection={{
          selectedRowKeys: selectedRows,
          onChange: (selected: any) => setSelectedRows(selected),
        }}
      />
    </div>
  );
};
