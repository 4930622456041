import { Col, Card, Space, Typography, Divider } from 'antd';
import { FC } from 'react';
import { DashboardCardProps } from './Dashboard.types';

export const DashboardCard: FC<DashboardCardProps> = ({ children, action, icon, title, total }) => {
  return (
    <Col span={4}>
      <Card bordered={false} className="dashboard-card">
        <Space direction="vertical" size="small">
          <div className="dashboard-card-action-get">
            <span>{icon}</span>
            {action && action}
          </div>
          <div>
            <Typography.Paragraph strong type="secondary" className="dashboard-card-p">
              {title}
            </Typography.Paragraph>
            <Typography.Title className="dashboard-card-title" level={4}>
              {total?.toLocaleString()}
            </Typography.Title>
          </div>
        </Space>
        <Divider className="dashboard-card-divider" />
        {children}
      </Card>
    </Col>
  );
};
